/* eslint-disable */
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
const employView = () => import("@/pages-smartbase/employ/employView.vue");
const employList = () => import("@/pages-smartbase/employ/employList.vue");
const NotAllowed = () => import('@/pages-smartbase/notAllowed.vue');
const NotFound = () => import('@/pages/error/NotFound.vue');
const AuthLogin = () => import('@/pages/auths/AuthLogin');
const userList = () => import('@/pages-smartbase/user/userList.vue');
const dashboardView = () => import('@/pages-smartbase/dashboard/dashboardView.vue');
const configView = () => import("@/pages-smartbase/config/configView.vue");
const payrollsList = () => import("@/pages-smarttrack/payroll/payrollList.vue");
const payrollAdjustmentList = () => import("@/pages-smarttrack/payrollAdjustment/payrollAdjustmentList.vue");
const payrollCreate = () => import("@/pages-smarttrack/payroll/payrollCreate.vue");
const payrollView = () => import("@/pages-smarttrack/payroll/payrollView.vue");

const tabletViewRegisterTime = () => import("@/pages-smarttrack/tablet/tabletViewRegisterTime.vue");
const tabletView = () => import("@/pages-smarttrack/tablet/tabletView.vue");
const tabletViewEmployee = () => import("@/pages-smarttrack/tablet/tabletViewEmployee.vue");
const tabletCreateEmployee = () => import("@/pages-smarttrack/tablet/tabletCreateEmployee.vue");

const roleList = () => import("@/pages-smarttrack/role/roleList.vue");
const bonusList = () => import("@/pages-smarttrack/bonus/bonusList.vue");


const notificationUnread = () => import("@/pages-smarttrack/notification/notificationUnread.vue");
const notificationList = () => import("@/pages-smarttrack/notification/notificationList.vue");

const jobList = () => import("@/pages-smarttrack/job/jobList.vue");
const companyList = () => import("@/pages-smarttrack/company/companyList.vue");
const routes = [
    {
        path: '/payrollAdjustment',
        name: 'Payroll Adjustment',
        component: payrollAdjustmentList,
    },
    {
        path: '/tablet/newTime/:employId',
        name: 'TabletViewnewTime',
        component: tabletViewRegisterTime,
    },
    {
        path: '/tablet/employee/:employId',
        name: 'TabletViewEmployee',
        component: tabletViewEmployee,
    },
    {
        path: '/tablet/employee/',
        name: 'tabletCreateEmployee',
        component: tabletCreateEmployee,
    },
    {
        path: '/notification/unread',
        name: 'notificationunread',
        component: notificationUnread,
    },
    {
        path: '/notification/list',
        name: 'notificationlist',
        component: notificationList,
    },

    {
        path: '/tablet',
        name: 'TabletView',
        component: tabletView,
    },
    {
      path: '/companies',
      name: 'companyList ',
      component: companyList,
    },
    {
      path: '/roles',
      name: 'RoleList ',
      component: roleList,
    },
    {
      path: '/jobs',
      name: 'joblist ',
      component: jobList,
    },
    {
      path: '/payroll',
      name: 'payroll ',
      component: payrollCreate,
    },
    {
      path: '/bonus',
      name: 'bonus ',
      component: bonusList,
    },
    {
      path: '/payroll/:id',
      name: 'payrollView ',
      component: payrollView,
    },
    {
      path: '/payrolls',
      name: 'payrolls ',
      component: payrollsList,
    },
    {
      path: '/users',
      name: 'User List',
      component: userList,
    },
    {
      path: '/',
      name: 'Blank',
      component: dashboardView
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: dashboardView
  },
    {
    path: '/employ/:id',
    name: 'Employ View',
    component: employView,
  },
      {
    path: '/employees',
    name: 'Employ List',
    component: employList,
  },
    {
    path: '/notAllowed',
    name: 'Not Allowed',
    component: NotAllowed
  },
  {
    path: '/config/',
    name: 'config View',
    component: configView
  },
  {
    path: '/auths/auth-login',
    name: 'Auth Login',
    component: AuthLogin
  },

  { path: '/:pathMatch(.*)*', 
    name: 'Page NotFound', 
    component: NotFound 
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
})

// this function dynamically change the route title of app and Redirect user to login page if not logged in
import {store, refreshSession} from '../state/store'
router.beforeEach((to, from, next) => {
  document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`

  if (to.path.includes('changelog')) {
    window.location = 'https://fvtec.notion.site/Changelog-Smartbase-d03ec738278a46038ee1df20b3353ae4?pvs=4'
    return;
  }
  const publicRoutes = ['/auths/auth-register', '/auths/auth-login','/auths/auth-reset'];
  if (publicRoutes.includes(to.path) || store.state.session.logged) {



    if (to.meta.permissionName) {
       
          var per = store.state.config.permissions;
          if (per && per.length >0) {
              if (!per.includes(to.meta.permissionName)) {
                next(`/notAllowed?permissionName=${to.meta.permissionName}`);
              } else {
                next();
            }
          } else {
             next();
          }

    } else {
      next();
    }

    

    
   
  
  }else{
    next('/auths/auth-login');
  }
})

export default router
